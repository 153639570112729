import React, { createContext, useState } from 'react'

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    return window.sessionStorage.getItem('token')
  })

  const user = JSON.parse(window.sessionStorage.getItem('user'))
  const [isSocket, setSocket] = useState(new WebSocket(`ws://localhost:8080/${user != undefined ? user.email: ""}`))

  const value = {
    isAuth,
    activateAuth: (token, user) => {
      setIsAuth(token)
      window.sessionStorage.setItem('token', token)
      window.sessionStorage.setItem('user', JSON.stringify(user))
    },
    isSocket
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

import React from "react"
import { FaHome, FaUser, FaBookMedical, FaCheck, FaCog, FaListAlt, FaRegArrowAltCircleDown, FaListOl } from 'react-icons/fa'
const user = JSON.parse(sessionStorage.getItem('user'))

const navigationAdmin = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "Sistema",
    title: "Sistema",
    type: "collapse",
    icon: <FaUser size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listUser",
        title: "Usuarios",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/listUser",
      },
    ]
  },
  {
    id: "Configuracion",
    title: "Configuración",
    type: "collapse",
    icon: <FaCog size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "parametros",
         title: "Parámetros",
         type: "item",
         icon: <FaCheck size={20} />,
         permissions: ["admin", "editor"],
         navLink: "/parametros"
      },
      {
        id: "categorias",
         title: "Categorías",
         type: "item",
         icon: <FaCheck size={20} />,
         permissions: ["admin", "editor"],
         navLink: "/categorias"
      },
      {
        id: "subcategorias",
         title: "SubCategorías",
         type: "item",
         icon: <FaCheck size={20} />,
         permissions: ["admin", "editor"],
         navLink: "/subcategorias"
      },
      {
        id: "actividadescontrato",
         title: "Actividades Contrato",
         type: "item",
         icon: <FaCheck size={20} />,
         permissions: ["admin", "editor"],
         navLink: "/actividadescontrato"
      },
      {
        id: "responsables",
         title: "Responsables",
         type: "item",
         icon: <FaCheck size={20} />,
         permissions: ["admin", "editor"],
         navLink: "/ResponsablesSolicitud"
      },
      {
        id: "ProcesosResponsables",
         title: "Procesos solicitud",
         type: "item",
         icon: <FaCheck size={20} />,
         permissions: ["admin", "editor"],
         navLink: "/ProcesosResponsables"
      },
    ]
  },
  {
    id: "Solicitudes",
    title: "Solicitudes",
    type: "collapse",
    icon: <FaListOl size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formulario",
        title: "Nueva solicitud",
        type: "item",
        icon: <FaCheck size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/formulario"
      },
      {
        id: "listadoFormulario",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/listadoFormulario"
      },
    ]
  },
]

const navigationActualizador = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "Solicitudes",
    title: "Solicitudes",
    type: "collapse",
    icon: <FaListOl size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formulario",
        title: "Nueva solicitud",
        type: "item",
        icon: <FaCheck size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/formulario"
      },
      {
        id: "listadoFormulario",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/listadoFormulario"
      },
    ]
  },
]

//Administrador
//Analista, crea solicitudes y no ve parametros
//Visor, solo genera reportes
const navigationConfig =
  user?.idperfil === 2 ? navigationAdmin :
  user?.idperfil === 3 ? navigationActualizador :
        []

export default navigationConfig